import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { 
        path: "/", 
        redirect: route => { 
            const params = { 
                w: (route.query.w ? route.query.w : '' ),   // workout (in sec)
                r: (route.query.r ? route.query.r : '' ),   // rest (in sec)
                s: (route.query.s ? route.query.s : '' ),   // sets (number)
                h: (route.query.h ? route.query.h : '' )    // HIIT 0|1
            };
            return { path: '/crono', query: params }
        }
    },
    {
        path: "/crono",
        component: () => import("@/views/CronoView.vue"),
        props: route => ({ 
            w: route.query.w,
            r: route.query.r, 
            s: route.query.s,
            h: route.query.h
        })
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
